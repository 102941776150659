import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="row">
          <div className="col">
            <h3 className="sub-heading">ALUMINIUM</h3>
            <ul>
              <li>
                <Link to="/aluminium/aluminium_doors">Aluminium Doors</Link>
              </li>
              <li>
                <Link to="/aluminium/aluminium_windows">Aluminium Windows</Link>
              </li>
              <li>
                <Link to="/aluminium/aluminium_pergola">Aluminium Pergola</Link>
              </li>
              <li>
                <Link to="/aluminium/bifolding_doors">Bi-Folding Doors</Link>
              </li>
              <li>
                <Link to="/aluminium/cast_aluminium_doors">
                  Cast Aluminium Doors
                </Link>
              </li>
              <li>
                <Link to="/aluminium/aluminium_staircase">
                  Aluminium Staircase
                </Link>
              </li>
              <li>
                <Link to="/aluminium/aluminium_composite_cladding">
                  Aluminium composite Cladding
                </Link>
              </li>
              <li>
                <Link to="/aluminium/aluminium_louver">Aluminium Louver</Link>
              </li>
              <li>
                <Link to="/aluminium/aluminium_mashrabiya">
                  Aluminium Mashrabiya
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="sub-heading">GLASS</h3>
            <ul>
              <li>
                <Link to="/glass/glass_partition">Glass Partition</Link>
              </li>
              <li>
                <Link to="/glass/shower_glass">Shower Glass</Link>
              </li>
              <li>
                <Link to="/glass/sliding_glass_doors">Sliding Glass Door</Link>
              </li>
              <li>
                <Link to="/glass/glass_railing">Glass Railing</Link>
              </li>
              <li>
                <Link to="/glass/curtain_walls">Curtain Wall</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="sub-heading">UPVC</h3>
            <ul>
              <li>
                <Link to="/upvc/upvc_doors_windows">
                  uPVC Slide and Fold Doors
                </Link>
              </li>
              <li>
                <Link to="/upvc/upvc_doors_windows">uPVC Sliding Doors</Link>
              </li>
              <li>
                <Link to="/upvc/upvc_doors_windows">uPVC Sliding Windows</Link>
              </li>
              <li>
                <Link to="/upvc/upvc_doors_windows">uPVC Fixed Window</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="sub-heading" onClick={() => window.scrollTo(0, 0)}>
              ABOUT US
            </h3>
            <ul>
              <li>
                <Link to="/contact_us">Contact-Us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </div>
        </div>
        <h3 className="sub-heading">
          Copyright © 2023. Aspect Aluminium and Glass Industry LLC.
        </h3>
      </div>
    </div>
  );
};

export default Footer;
