import Footer from "../components/footer/Footer";
import Gallery from "../components/gallery/Gallery";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";

const Projects = () => {
  return (
    <div>
      <Navbar />
      <SimpleHeader title={"our projects"}/>
      <Gallery />
      <Footer />
    </div>
  );
};

export default Projects;
