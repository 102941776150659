import { Link } from "react-router-dom";
import "./cards.css";

const Cards = (card) => {
  return (
    <div
      className="card"
      data-aos="flip-left"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
    >
      <img src={card.img} alt={card.heading} />
      <div className="content">
        <h3 className="sub-heading">{card.heading}</h3>
        <p>{card.paragraph}</p>
        <Link to={card.link}>
          <button className="btn"> view more</button>
        </Link>
      </div>
    </div>

    /* <div
          className="card"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <img src="/img/card-2.jpg" alt="Bi-Folding Doors" />
          <div className="content">
            <h3 className="sub-heading">Bi-Folding Doors</h3>
            <p>
              Bifold Doors that are made of Aluminium are the most strong,
              functional, and secure doors.
            </p>
            <button className="btn">view more</button>
          </div>
        </div>
        <div
          className="card"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <img src="/img/card-3.jpg" alt="Aluminium Doors" />
          <div className="content">
            <h3 className="sub-heading">Aluminium Doors</h3>
            <p>
              Interior Doors are the most important element in the interior
              decoration of space.
            </p>
            <button className="btn">view more</button>
          </div>
        </div>
        <div
          className="card"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <img src="/img/card-4.jpg" alt="Aluminium Windows" />
          <div className="content">
            <h3 className="sub-heading">Aluminium Windows</h3>
            <p>
              Windows are vital for the functionality of our spaces .They
              provide an aesthetic view and brightning light
            </p>
            <button className="btn">view more</button>
          </div>
        </div> */
  );
};

export default Cards;
