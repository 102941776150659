import { Link } from "react-router-dom";
import "./maincast.css";
import { ParallaxBanner } from "react-scroll-parallax";

const MainCast = () => {
  return (
    <ParallaxBanner
      layers={[{ image: "/img/bifold-doors.jpg", speed: -30 }]}
      className="aspect-[2/1]"
    >
      <div className="main-cast-container">
        <div className="main-cast">
          <div className="content">
            <h2 className="heading-secondary">Cast Aluminium Doors</h2>
            <p>
              We take great pleasure in introducing our products as we are
              confident about the quality we supply to our clients. We are
              successful because of the high-quality products we provide under
              an affordable budget. We don’t only provide new doors but we can
              redesign your previous doors as well according to your wishes. We
              deal with all kind of aluminium doors
            </p>
            <Link to={"/aluminium/aluminium_doors"}>
              <button className="btn">view more</button>
            </Link>
          </div>
          <div className="content">
            <h2 className="heading-secondary">Kitchen & Cabinet</h2>
            <p>
              Aspect offers you aluminium Kitchen Cabinets. The quality of
              aluminium cannot be stressed enough and Kitchen Cabinets made up
              of aluminium are more durable, anti-rust, waterproof and have many
              other countless properties. Talking about other materials for
              example wood cabinets they are easily damaged as they are not
              waterproof.
            </p>
            <Link to="/kitchen_and_cabinets">
              <button className="btn">view more</button>
            </Link>
          </div>
        </div>
      </div>
    </ParallaxBanner>
  );
};

export default MainCast;
