import "./careerDetails.css";

const CareerDetails = ({ CareerData }) => {
  return (
    <div className="accounts-container">
      <div className="accounts">
        <h3 className="heading-secondary">Job Description</h3>
        <p>{CareerData.job_description}</p>
        <h3 className="heading-secondary">Responsibilities</h3>
        <p>{CareerData.responsibilities?.paragraph}</p>
        <ul>
          {CareerData.responsibilities?.lists.map((li, index) => (
            <li key={index}>{li}</li>
          ))}
        </ul>
        <h3 className="heading-secondary">Requirements</h3>
        <p>{CareerData.requirements?.paragraph}</p>
        <ul>
          {CareerData.requirements?.lists.map((li, index) => (
            <li key={index}>{li}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CareerDetails;
