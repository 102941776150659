import BgSection from "../components/bgSection/BgSection";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import HomeCard from "../components/homeCards/HomeCard";
import MainCast from "../components/main-cast/MainCast";
import Navbar from "../components/navbar/Navbar";
import Service from "../components/services/Service";
import StairsAndGlass from "../components/stairsAndGlass/StairsAndGlass";

const home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Service />
      <MainCast />
      <StairsAndGlass />
      <HomeCard />
      <BgSection />
      <div className="home-contact-container">
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default home;
