import Footer from "../components/footer/Footer";
import KitchenCabinets from "../components/kitchenCabinets/KitchenCabinets";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";

const Kitchen = ({ product }) => {
  return (
    <div>
      <Navbar />
      <SimpleHeader title={"Kitchen & Cabinets"} />
      <KitchenCabinets />
      <Footer />
    </div>
  );
};

export default Kitchen;
