import "./serviceList.css";
import { ListData } from "../../cardData";
import Lists from "../../ui/lists/Lists";

const ServiceList = ({ list }) => {
  return (
    <div className="serviceList">
      <div className="serviceList-container">
        <h2 className="heading-secondary">Our Services</h2>
        <p>
          ASPECT ALUMINIUM & GLASS – UAE, is a design, manufacture &
          installation company based in Dubai, United Arab Emirates and
          specializing in creative, design products & solutions.
        </p>
        <div className="lists">
          {ListData[list].map((list) => (
            <Lists key={list.id} {...list} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceList;
