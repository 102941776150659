export const cardsData = {
  serviceCard: [
    {
      id: 1,
      img: "/img/card-1.jpg",
      heading: "Aluminium Pergola",
      paragraph:
        "Aluminium Pergola manufactured at Aspect in Dubai is the best place to get Pergol",
      link: "/aluminium/aluminium_pergola",
    },
    {
      id: 2,
      img: "/img/card-2.jpg",
      heading: "Bi-Folding Doors",
      paragraph:
        "Bifold Doors that are made of Aluminium are the most strong, functional, and secure doors.",
      link: "/aluminium/bifolding_doors",
    },
    {
      id: 3,
      img: "/img/card-3.jpg",
      heading: "Aluminium Doors",
      paragraph:
        "Interior Doors are the most important element in the interior decoration of space.",
      link: "/aluminium/aluminium_doors",
    },
    {
      id: 4,
      img: "/img/card-4.jpg",
      heading: "Aluminium Windows",
      paragraph:
        "Windows are vital for the functionality of our spaces .They provide an aesthetic view and brightning light",
      link: "/aluminium/aluminium_windows",
    },
  ],

  homeCard: [
    {
      id: 5,
      img: "/img/card-5.jpg",
      heading: "Shower Glass",
      paragraph:
        "Shower Glass is a very trendy interior used nowadays in every home to showcase a fancy look.",
      link: "/glass/shower_glass",
    },
    {
      id: 6,
      img: "/img/card-6.jpg",
      heading: "Sliding Glass Door",
      paragraph:
        "Glass Door Company with options such as sliding Glass Door replacement. They provide a modern look to your home.",
      link: "/glass/sliding_glass_doors",
    },
    {
      id: 7,
      img: "/img/card-7.jpg",
      heading: "Glass Railing",
      paragraph:
        "Glass railings aren’t something that everyone can handle as they are fragile.They must be handled with care",
      link: "/glass/glass_railing",
    },
    {
      id: 8,
      img: "/img/card-8.jpg",
      heading: "Curtain Walls",
      paragraph:
        "Curtain Walls in different shapes and various color options that are the worst weather resistant.",
      link: "/glass/curtain_walls",
    },
  ],
};

export const ListData = {
  glass: [
    {
      id: 1,
      img: "/img/glassPartition.jpg",
      heading: "Glass Partition",
      paragraph:
        "Glass partition which is the feature of interior design creates an aesthetically attractive pleasing environment which gives a tasteful impression be it at an office or even at home.",

      link: "/glass/glass_partition",
    },

    {
      id: 2,
      img: "/img/showerGlass.jpg",
      heading: "Shower Glass",
      paragraph:
        "Shower Glass is a very trendy interior used nowadays in every home to showcase a fancy look. It has become a new modernized trend.",

      link: "/glass/shower_glass",
    },
    {
      id: 3,
      img: "/img/slidingGlassDoor.jpg",
      heading: "Sliding Glass Door",
      paragraph:
        "Dubai at Aspect is the best Glass Door Company with options such as sliding Glass Door replacement, sliding folding door, sliding patio door, automatic sliding door and many others.",

      link: "/glass/sliding_glass_doors",
    },
    {
      id: 4,
      img: "/img/glassRailing.jpg",
      heading: "Glass Railing",
      paragraph:
        "Draw out the view of your home with our unbeatable quality of glass railings which helps to enhance your living space.",

      link: "/glass/glass_railing",
    },
    {
      id: 5,
      img: "/img/card-8.jpg",
      heading: "Curtain Walls",
      paragraph:
        "Aspect offers a variety of Curtain Walls in different shapes, depths, finishes, and various color options that are the worst weather resistant.",

      link: "/glass/curtain_walls",
    },
  ],

  aluminium: [
    {
      id: 1,
      img: "/img/aluminiumDoors.jpg",
      heading: "Aluminium Doors",
      paragraph:
        "Interior Doors are the most important element in the interior decoration of space which must be made ideal to give a profound effect, and volatizing description to your space.",

      link: "/aluminium/aluminium_doors",
    },
    {
      id: 2,
      img: "/img/aluminiumWindows.jpg",
      heading: "Aluminium Windows",
      paragraph:
        "Windows are vital for the functionality of our spaces as they help up with the ventilation hence provide a view too. They provide us with an aesthetic view and brightening light.",

      link: "/aluminium/aluminium_windows",
    },
    {
      id: 3,
      img: "/img/aluminiumPergola.jpg",
      heading: "Aluminium Pergola",
      paragraph:
        "Aluminium Pergola manufacturers in Dubai is the best place to get Pergola for your space and give your space a volume and sense of style to make your day and environment better",

      link: "/aluminium/aluminium_pergola",
    },
    {
      id: 4,
      img: "/img/bifoldingDoors.jpg",
      heading: "Bi-Folding Doors",
      paragraph:
        "Bifold Doors that are made of aluminium are the most strong, functional, and secure doors. Bi-fold engineered doors are available in 2 to 8 folds, even more than that in different designs depending on the customer’s choice.",

      link: "/aluminium/bifolding_doors",
    },
    {
      id: 5,
      img: "/img/castAluminiumDoors.jpg",
      heading: "Cast Aluminium Doors",
      paragraph:
        "We take great pleasure in introducing our all products as we are confident about the quality we supply to our clients. We are this successful because of the high-quality products we provide under an affordable budget.",

      link: "/aluminium/cast_aluminium_doors",
    },
    {
      id: 6,
      img: "/img/aluminiumStaircase.jpg",
      heading: "Aluminium Stair Case",
      paragraph:
        "Staircases nowadays have become an important part of the architecture world. Stairs are the focal point of a space due to which it is very necessary to install yourself a great design of stairs that are durable and minimal.",

      link: "/aluminium/aluminium_staircase",
    },
    {
      id: 7,
      img: "/img/aluminium-composite-cladding.jpeg",
      heading: "Aluminium composite Cladding",
      paragraph:
        "Aluminium composite cladding system metal curtain wall system. ACP is made from lightweight aluminium with hard insulation. It is used for exterior cladding, column covers, fascia’s and canopies, interior wall and partition panels, parapets, louvers and sunshades.",

      link: "/aluminium/aluminium_composite_cladding",
    },
    {
      id: 8,
      img: "/img/aluminium-louver.jpeg",
      heading: "Aluminium Louver",
      paragraph:
        "Aspect Aluminium provides aluminium louvres in Dubai and other emirates of UAE. People use aluminium louvres systems in the construction of their homes, mostly to protect themselves from the sun. ",

      link: "/aluminium/aluminium_louver",
    },
    {
      id: 9,
      img: "/img/aluminium-mashrabiya.jpeg",
      heading: "Aluminium Mashrabiya",
      paragraph:
        "Mashrabiya has close connections with the harsh desert climate, and that’s the reason why mashrabiya is utilized the most. With more of aesthetic charm, mashrabiyas provide sufficient gust of air into the room. ",

      link: "/aluminium/aluminium_mashrabiya",
    },
  ],

  upvc: [
    {
      id: 1,
      img: "/img/upvcSlideFold.jpg",
      heading: "uPVC Slide & Fold Doors",
      paragraph:
        "uPVC Slide and Fold Doors are used mainly in large open areas to appear like a virtual wall. The door is a collection of multiple frames which has fixed or moving frames.",

      link: "/upvc/upvc_doors_windows",
    },
    {
      id: 2,
      img: "/img/upvcSlidingDoors.jpg",
      heading: "uPVC Sliding Doors",
      paragraph:
        "Because of its sturdiness and longevity, uPVC sliding doors are incredibly durable and require little maintenance",

      link: "/upvc/upvc_doors_windows",
    },
    {
      id: 3,
      img: "/img/upvcSlidingWindows.jpg",
      heading: "uPVC Sliding Windows",
      paragraph:
        "An uPVC Sliding window comprise of frames that can slide back and forth. The technical term for frames here is sash. With great ventilation and aesthetics, uPVC sliding windows offer unmatchable high-quality uPVC frames coupled with Glass of choice",

      link: "/upvc/upvc_doors_windows",
    },
    {
      id: 4,
      img: "/img/upvcTiltAndTurn.jpg",
      heading: "uPVC Tilt & Turn Window",
      paragraph:
        "uPVC Tilt and Turn Windows are a great choice when it comes to choosing a window as it has a perfect blend of intelligent operability and smart asthetics.",

      link: "/upvc/upvc_doors_windows",
    },
    {
      id: 5,
      img: "/img/upvcFixedWindow.jpg",
      heading: "uPVC Fixed Window",
      paragraph:
        "The purpose of uPVC Fixed Windows is to provide architectural completion and aesthetical framing of the house. As the name suggests, they are fixed and don’t have any moving or operable parts. Usually these windows are installed at a height where ample amount of sunlight can enter the room",
      link: "/upvc/upvc_doors_windows",
    },
  ],
};

export const ProductData = {
  aluminium_doors: [
    {
      id: 1,
      img: "/img/card-3.jpg",
      heading: "High-Quality Aluminium, Pivot, & Revolving Doors",
      paragraph:
        "Interior Doors are the most important element in the interior decoration of space which must be made ideal to give a profound effect, aesthetic statement, and volatizing description to your space. A door is chosen according to your space and architecture as there are many models of doors available. We at Dubai at Aspect serves you with the best doors to enhance the beauty of your space and give it a solid look.",
    },
    {
      id: 2,
      img: "/img/aluminium-doors.jpg",
      heading: "Aluminium Doors",
      paragraph:
        "The quality of aluminium cannot be stressed enough, it is the best material. Aluminium doors are that specific type of doors that can adapt themselves to any space and its style. Aluminium is a very trending material that is used for both Aluminium windows and doors. Aluminium doors also serve as a perfect match with Aluminium Windows. Aluminium doors are suitable both for homes and industrial usage. Aspect provide Aluminium powder coated doors with various colours and designing options according to our client’s space that blends in with their space’s interior and requires very little maintenance. Height, width, and other measurements of the door are kept according to the ceiling.",
    },
    {
      id: 3,
      img: "/img/pivot-doors.jpg",
      heading: "Pivot Doors",
      paragraph:
        "Now avail sleek pivot doors at Aspect with a unique vertical pivot opening with a smooth operation of panels to give your space a unique, exceptional look and give an overall quality to your home. The pivot door system is excellent and useful for doors that contain large glass. Glass and wood pivot doors give a look of sheer modernity, warmth, and elegance that no other door can provide nowadays. Our team makes sure to manufacture strong pivot doors, heat and sound insulated.",
    },
    {
      id: 4,
      img: "/img/revolving_doors.png",
      heading: "Revolving Doors",
      paragraph:
        "Revolving doors are one of the most professional doors that are used as the first visual focus while entering buildings, hotels, offices, etc. That give a fancy yet professional touch to that specific space. Revolving doors serve as an attractive feature. Aspect not only provides doors for homes but for industrial purposes as well according to their priority and professional style. As far as the material is concerned our team makes sure to manufacture premium quality revolving doors as they are used for professional spaces, we make sure to make them long-lasting.",
    },
  ],

  aluminium_windows: [
    {
      id: 1,
      img: "/img/Best-Aluminium-Window.jpeg",
      heading: "Best Aluminium Windows",
      paragraph:
        "Windows are vital for the functionality of our spaces as they help up with the ventilation hence provide a view too. They provide us with an aesthetic view and brightening light. The aluminium interior has replaced all other interiors in the market as it has been a game-changer and has set a remark in the interior industry. Aluminium is the best and the right choice for anyone who’s thinking of updating their interior. There are many types of windows available at Aspect with new window designs and styles to elevate, boost, and strengthen your space’s look. Our clients matter to us the most and are our top priority, and their designing priority is our priority.",
    },
    {
      id: 2,
      img: "/img/aluminium-sliding-window.jpg",
      heading: "Aluminium Sliding Window",
      paragraph:
        "Windows are vital for the functionality of our spaces as they help up with the ventilation hence provide a view too. They provide us with an aesthetic view and brightening light. The aluminium interior has replaced all other interiors in the market as it has been a game-changer and has set a remark in the interior industry. Aluminium is the best and the right choice for anyone who’s thinking of updating their interior. There are many types of windows available at Aspect with new window designs and styles to elevate, boost, and strengthen your space’s look. Our clients matter to us the most and are our top priority, and their designing priority is our priority. We try our best to change their vision into reality through our creativity and stunning designs with minimal yet deep details.",
    },
    {
      id: 3,
      img: "/img/double-glazed-windows.jpg",
      heading: "Double Glazed Windows",
      paragraph:
        "Double glazed windows are also known as insulated glass units. If you are looking for double-glazed windows, you are at the right place. Our double-glazed windows are created keeping in mind the insulation. Aspect offers the best double-glazed windows that act more relaxed in summer, warmer in winters, and prevent unwanted noise for a calmer environment as compared to single glazed windows. There are many advantages and benefits that double glazed windows can provide compared to single glazed windows, as stated above. We offer different sorts of pastel colours and frames, be it PVC, wood, or timber of high verified quality, depending on the client’s choice. Double glazed windows are worth buying.",
    },
    {
      id: 4,
      img: "/img/casement-window.jpg",
      heading: "Bay and Casement Window",
      paragraph:
        "Bay and Casement windows at Aspect are made by combining three or more windows of exceptional designs as per clients’ choice and will. They are saving energy; our elegant casement windows are attractive. All our casement windows have an excellent energy performance rating and fall under industry security standards. We make sure to keep all the factors in our mind while designing for our clients. There are further many types of Bay and casement windows, such as the canted bay windows or the boxy bay windows, and the list goes on. The modern bay and Casement windows have small frames with large expanses of glass.",
    },
  ],

  aluminium_pergola: [
    {
      id: 1,
      img: "/img/aluminium-pergola.jpg",
      heading: "Best Aluminium Pergola",
      paragraph:
        "Are you looking for the best Aluminium Pergola to extend your living space? Aluminium Pergola manufacturers in Dubai at Aspect is the best place to get Pergola for your space and give your space a volume and sense of style to make your day and environment better If you are someone who loves spending time outdoors, our Pergola is the best option for you and worth buying because it will be the best statement for your space available in every colour. Pergola serves several various services.",
    },
    {
      id: 2,
      img: "/img/gazebo-builder.jpg",
      heading: "Gazebo Builder",
      paragraph:
        "Aspect builds the best Gazebo for one’s property, which provides a decent yet richness in the look. Our Gazebos are constructed in many materials such as wood, which provides a warm atmosphere to the whole surrounding and the wood can be painted as well as per our client’s choice and preference. Our Gazebos are strength-wise constructed by using sheer quality material in a way that they can last through any worse weather condition. Our team makes sure to provide our client a customized plan for Gazebo builder including the style, colour, and dimension.",
    },
    {
      id: 3,
      img: "/img/aluminiumPergola.jpg",
      heading: "Modern Aluminium Pergola",
      paragraph:
        "Aluminium Pergola is a very trending feature nowadays as the world is changing. If you are looking for a modern Pergola, Aluminium is the best option for it. Aluminium itself has many flawless qualities that cannot be found in any other material. Aluminium Pergola lasts longer than any other material available. Modern Aluminium Pergola serves the best looks wherever it is placed, besides looks its quality is unmatchable. Our Pergola is multifunctional, it protects you from both the sun and heavy rain.",
    },
    {
      id: 4,
      img: "/img/aluminium-roof-pergola.jpg",
      heading: "Aluminium Roof Pergola",
      paragraph:
        "Our Aluminium Pergola is also available with an adjustable louvered roof in various designs. Our Aluminium Roof Pergola offers functionality, durability, and a visually aesthetic appearance. The best thing about our Roof Pergola is that their louvered roof can be closed as well. Yes, our Aluminium Roof Pergola has multiple functions such as most importantly it is rainproof and maintenance-free. Aluminium roof pergola is available both for residential and commercial usage. In commercial use, Aluminium Roof Pergola offers great versatility and looks which attracts the customers.",
    },
    {
      id: 5,
      img: "/img/outdoor-pergola.jpg",
      heading: "Outdoor Pergola",
      paragraph:
        "If you are looking for an outdoor Pergola, Aspect is your best choice to select the right model of outdoor Pergola. Our outdoor Pergola gives you the chance to enjoy the weather without being worried. Our Outdoor Pergola not only can support plants on its rooftop but makes it easy to hang other flowers and lanterns according to your own choice to give it a smooth look and touch in your outer space",
    },
    {
      id: 6,
      img: "/img/glass-pergola.jpg",
      heading: "Glass Pergola",
      paragraph:
        "Glass Pergola can never be outdated as they serve fancier looks than the Pergola even though they are on the expensive side, but Glass Pergola is one of the prettiest structures you will have ever seen which is manufactured by the usage of stronger material. Our Glass Pergolas pillars are available as well in many colours’ pallets of different shades and designs that will make you book yourself a Glass Pergola at Aspect.",
    },
  ],

  bifolding_doors: [
    {
      id: 1,
      img: "/img/bifoldingDoors.jpg",
      heading: "Aluminium Bi-Folding Doors",
      paragraph:
        "Bifold Doors that are made of Aluminium are the most strong, functional, and secure doors. Bi-fold engineered doors at Aspect are available in 2 to 8 folds, even more than that in different designs depending on the customer’s choice. Our designers at Aspect create the perfect harmony, great sense of designs and provide balance according to the space that is compatible with all sorts of the interior. Aluminium Bi-Fold Doors are the type of doors that are never out of style and are always serving a minimal, decent and elegant look with a smooth movement inwards or outwards because of our Aluminium Bi-Folding doors are manufactured by blending high-quality products according to its requirements. We make sure not to compromise on the quality and use any cheap product. Our powder-coated Aluminium Bi-Folding Doors are the safest option as they are not fragile or easily breakable as they are made up of stiff quality.",
    },
    {
      id: 2,
      img: "/img/sliding_pocket_doors.jpg",
      heading: "Sliding Pocket Door",
      paragraph:
        "Pocket doors are a fancy and modern style of door with a sliding option. At Aspect you can find many Sliding Pocket doors in different styles and designs such as double pocket doors or wood pocket doors. Just like our other products, sliding pocket doors can be custom-built as well according to the client’s preference. Different colours and options are in sliding pocket doors. The most useful feature of sliding pocket doors is that they save space and can be used in a tight location in any part of the house where other options are not suitable. On the other hand, it can be used in large spaces as well to divide the room in a fancy way that creates a more welcoming environment, a sliding pocket door simply blends in the interior no matter where they are being used.",
    },
    {
      id: 3,
      img: "/img/rollup_garage_door.jpg",
      heading: "Roll Up Garage Door",
      paragraph:
        "Bifold Doors that are made of Aluminium are the most strong, functional, and secure doors. Bi-fold engineered doors at Aspect are available in 2 to 8 folds, even more than that in different designs depending on the customer’s choice. Our designers at Aspect create the perfect harmony, great sense of designs and provide balance according to the space that is compatible with all sorts of the interior. Aluminium Bi-Fold Doors are the type of doors that are never out of style and are always serving a minimal, decent and elegant look with a smooth movement inwards or outwards because of our Aluminium Bi-Folding doors are manufactured by blending high-quality products according to its requirements. We make sure not to compromise on the quality and use any cheap product. Our powder-coated Aluminium Bi-Folding Doors are the safest option as they are not fragile or easily breakable as they are made up of stiff quality.",
    },
  ],
  cast_aluminium_doors: [
    {
      id: 1,
      img: "/img/best-cast-aluminium-gates.jpg",
      heading: "Best Cast Aluminium Gates Manufacturer",
      paragraph:
        "We take great pleasure in introducing our all products as we are confident about the quality we supply to our clients. We are this successful because of the high-quality products we provide under an affordable budget. We don’t only provide new doors, but we can redesign your previous doors as well according to your wishes. An Aluminium door is the best choice in terms of quality, maintenance as it requires very little maintenance and lasts for a longer period maintaining its structure and style. Our doors are fully furnished, powder-coated, and fabricated by the usage of the highest quality material. All doors are measured according to the customer’s space and dimension.",
    },
    {
      id: 2,
      img: "/img/cast-aluminium-gates.jpg",
      heading: "Cast Aluminium Gates",
      paragraph:
        "The best gate that you can avail of for your space is our Cast Aluminium Gate with qualities that you will never be able to find somewhere else. Our Cast Aluminium Gate will enhance the architecture of your space with its decent style and colours. We offer the best colour combinations. Our cast Aluminium is the best material that is resistant to weather which doesn’t change its structure but remains in the same rigid structure no matter how worse the weather condition gets. No matter what style you are looking for be it classical, modern, fancy or any other Aspect has you all covered. We can design any type of Gate our customers want according to their customized plan. We will keep their preference our priority. Our designers listen to the client’s wishes and create the best and perfect gate for entrance.",
    },
    {
      id: 3,
      img: "/img/aluminium-entry-door.jpg",
      heading: "Aluminium Entry Door & Gate",
      paragraph:
        "An aluminium entry door and gate have numerous advantages as compared to any other material. For example, the structure ability, strength, thermal insulation, weather resistance, acoustic insulation, and many other qualities. Combining all these flawless and premium qualities makes the Aluminium Entry Door and Gate worth buying. The biggest quality is that at Aspect the workers put all their hard work in combining all these qualities and manufacturing the best aesthetically pleasing Aluminium Entry Door and Gate. The entrance door is the main part of a house or any property which catches the first impression of an individual and our company makes sure to provide our clients with a door design that will change the look of your space.",
    },
    {
      id: 4,
      img: "/img/aluminium-louver-door.jpg",
      heading: "Aluminium Louver Door",
      paragraph:
        "In our various options for Aluminium gates and doors, Aluminium Louver Door is available as well at Aspect and Glass Companies. Many people are very about the security issue when installing the Louver door, safe to say that you don’t have to worry about the security. Our Aluminium Louver door is manufactured keeping the security factor a priority. The modern equipment and technology that we use in manufacturing our Aluminium Louver Door ensure safety. Besides this, we also offer several various accessories with our Aluminium Louver Door. Our Aluminium Louver doors provide free passage that reflects light and is easy to clean as well. Aluminium Louver doors are the best source for ventilation in the space as they control the airflow through their space.",
    },
    {
      id: 5,
      img: "/img/modern-front-door.jpg",
      heading: "Modern Front Door in Dubai",
      paragraph:
        "Our Modern Front Door variety in Dubai is one of our best collections in which we offer many modern Doors that are automatic as well. An infinite selection of colour shades is provided as well so that clients can choose the shade according to their architecture. Mate or glossy, structural, or metallic colour tones all are available under Aspect. Our Modern Front Doors are manufactured using thick insulating leaves, three to four layers of glazing, and triple sealing to make it highly thermal insulated. In this way, our modern front door serves multiple functions, prevents heat loss, and reduces energy costs.",
    },
  ],
  aluminium_staircase: [
    {
      id: 1,
      img: "/img/spiral-staircase.jpg",
      heading: "Spiral and Round Staircase",
      paragraph:
        "Staircases nowadays have become an important part of the architecture world. Stairs are the focal point of a space due to which it is very necessary to install yourself a great design of stairs that are durable and minimal. Aspect all top quality highly finished and welded stairs meet all the standards of clients. Glass, wood, aluminium, metal, and stainless steel everything you need is available here.",
    },
    {
      id: 2,
      img: "/img/modern-staircase.jpg",
      heading: "Modern Staircases",
      paragraph:
        "Aspect offers a Modern staircase in Dubai with various designs and installations. A huge collection of modern staircases in wood, steel, aluminium, glass, and many other materials are available for clients to choose from. We offer our customers custom modern staircases in Dubai that are manufactured, engineered, and designed to fulfil the specific requirements of our clients. Incredible strong strength, durable, long-lasting value, and flawless finishing all qualities are available in our Modern Staircase in Dubai.",
    },
    {
      id: 3,
      img: "/img/aluminiumStaircase.jpg",
      heading: "Spiral Staircase",
      paragraph:
        "Wood, steel, metal, concrete, and glass all options are available for Spiral Staircase Dubai with their edges designed. Working on a Spiral staircase is a huge project because the manufacturing of a spiral staircase with custom measurements is huge complexity and further takes a lot of time and effort to design it flawlessly according to the space’s measurements, height, and diameter, etc. Diameter is the most option as it determines if the staircase even fits in the space aesthetically or not.",
    },
    {
      id: 4,
      img: "/img/round-staircase.jpg",
      heading: "Round StairCases",
      paragraph:
        "We put our efforts to enhance the details of Round Staircase in Dubai which matches the whole interior of our client’s space. We combine various materials such as glass and steel to accomplish an aesthetic architectural round stairway. The major concern of our company is to build safety filled around staircases. Just select the design, colour, and material and leave the rest to our engineers. They will bring out the best according to your needs, selection, and priorities. Most people assume that round staircases have been nowadays outdated but no they haven’t, they are still highly used all over.",
    },
    {
      id: 5,
      img: "/img/railing-stairs.jpg",
      heading: "Aluminium Railing & Stairs",
      paragraph:
        "Aluminium is by far the best option for railing and stairs. Aluminium at Aspect is the best Aluminium work company material available that is durable, water-resistant, heat resistant and has many other countless qualities with smooth options of furnishing is available. Moreover, our Aluminium Railing and Stairs are also further powder coated to give a neat and representable look. Aluminium railing from Aspect is made up of a blend of by blend of unique materials. Our aluminium stairs railing, and grill designs provide a sleek finish and touch to stairs or any product that it is used in.",
    },
  ],
  aluminium_composite_cladding: [
    {
      id: 1,
      img: "/img/aluminium-composite-cladding.jpeg",
      heading: "Aluminium composite Cladding",
      paragraph:
        "Aluminium composite cladding system or ACP is typically a metal curtain wall system. ACP is made from lightweight aluminium with hard insulation. Aluminium composite cladding is used for exterior cladding, column covers, fascia’s and canopies, interior wall and partition panels, parapets, louvers and sunshades. We have extensive experience and skill set in providing ACP fabrication drawings and ACP installation drawings for offices,  shopping malls, apartments and hospitals. Our expertise in ACP detailing also include 2D & 3D detailing & drafting. We have a highly successful track record and a growing but repetitive clientele which prove the value of our services. We meet the client’s demands and expectations with focus on quality and reliability.",
    },
  ],
  aluminium_louver: [
    {
      id: 1,
      img: "/img/aluminium-louver.jpeg",
      heading: "Aluminium Louver",
      paragraph:
        "Aspect Aluminium providing aluminium louvres in Dubai and other emirates of UAE. Aluminium Louvres have been an important part of the architecture. People used aluminium louvres systems in the construction of their homes, mostly to protect themselves from the sun. Aluminium louvres are shutters that have horizontal slats that can be put into different angles to shade from the sun. In the present day we mainly place aluminium louvers for decorative purposes and architectural beauty. Louvres were often made, and sometimes are still made from wood.",
    },
    {
      id: 2,
      img: "/img/aluminium-louver2.jpeg",
      heading: "Modern Aluminium Louver",
      paragraph:
        " Modern aluminium louvres systems are built with metals like stainless steel, titanium, and aluminium. Occasionally louvres are also made of glass or copper. Louvre systems are also used in front doors, closets and ceilings. The use of louvres inside the home is often shading, ventilation, or just decoration. Aluminium louvres can be built to help sun control or temperature control for buildings. Many architects also choose louvres to decorate their design.",
    },
  ],
  aluminium_mashrabiya: [
    {
      id: 1,
      img: "/img/aluminium-mashrabiya.jpeg",
      heading: "Aluminium Mashrabiya",
      paragraph:
        "Mashrabiya has close connections with the harsh desert climate, and that’s the reason why mashrabiya is utilized the most. Arabic countries have intense heat and severe aridity. This problem demands a more smart solution to sustain life and existence. People tired of the heat from the desert and brought many solutions to resist the heat. One method was that heavy walls were used because people thought that in this way, the heat would lessen. Even though these walls were effective in reducing the heat since the walls absorbed cold temperature at night. But the problem with this technique is that the walls had tiny ventilation holes which resulted in dimly lit home. With mashrabiya, the problem was solved to a great extent by letting in enough light and prevented heat seeping in through the wall opening. With more of aesthetic charm, mashrabiyas provide sufficient gust of air into the room.",
    },
  ],

  glass_partition: [
    {
      id: 1,
      img: "/img/glassPartition.jpg",
      heading: "Glass Partition",
      paragraph:
        "Glass partition which is the feature of interior design creates an aesthetically attractive pleasing environment which gives a tasteful impression be it at an office or even at home. Glass partition allow much lighter to pass through – making for a brighter, airier environment. Natural lightening system has been provided for better vision. Manual and automatic options for revolving doors are also available at Aspect and Glass companies.",
    },
    {
      id: 2,
      img: "/img/aluminium-partition2.jpg",
      heading: "Aluminium Partition",
      paragraph:
        "Aluminium partitions prove to be one of the most durable partitioning systems available, as they require less maintenance hence this is exactly why people prefer more Aluminium glass. If you’re looking for aluminium glass partition, we offer huge diversity of sleek aluminium glass manufactured of the best high quality and design that meets our customer priority. Aluminium is more resistant to rust and corrosion than any other metal.",
    },
    {
      id: 3,
      img: "/img/aluminium-partition.jpg",
      heading: "Qualities of Aluminium Partition",
      paragraph:
        "Our aluminium glass partition not only serves as an eye-catching interior but guarantees the conversation remain private in the space as compared to others. In addition to privacy, it helps to diminish noise to a great extent which helps to work more productively. Talking about winters, our Aluminium windows have a polyamide thermal break so that the cold stays out and the warm stays in. As compared to other partition options, aluminium partition wall doesn’t take up much space instead they are incapable of blending like its glass or cement counterpart.",
    },
    {
      id: 4,
      img: "/img/glassPart.jpg",
      heading: "Glass Partition",
      paragraph:
        "Aspect comes with all space planning in the hospitality industry, the frameless glass wall systems can optimize all area within very decent architectural design. Glass partitioning systems are available at Aspect with frameless glass as well for full height glazing for a smooth and sleek finish available in different colours and variety of designs that are fixed with the help of our professional team according to your choice, Constructed from high-quality materials – tempered or laminated glass.",
    },
    {
      id: 5,
      img: "/img/glass-work.jpg",
      heading: "Glass Work",
      paragraph:
        "We at Dubai at Aspect offer you the best refined glass that is manufactured perfectly without any compromise on its quality as glass is being used widely nowadays be it doors, partitions, cupboards because it enhances the beauty of a certain place with its transparent characteristics. We supply a wide range of glass at reasonable prices. Our professional staff makes sure to install the glass carefully and flawlessly.",
    },
    {
      id: 6,
      img: "/img/frameless-glass-door.jpg",
      heading: "Frameless Sliding Glass Door",
      paragraph:
        "Sliding glass doors at Aspect serves as an appearance of beauty with minimal framing of aluminium as we have expertise in them. With up to the mark sliding doors, you can expect stainless steel components and hardware, laminated safety glass, smooth and polished running sliding panels and a secure locking mechanism.",
    },
  ],

  shower_glass: [
    {
      id: 1,
      img: "/img/shower-glass.jpg",
      heading: "Shower Glass",
      paragraph:
        "Shower Glass is a very trendy interior used nowadays in every home to showcase a fancy look. It has become a new modernized trend. Glass let’s anyone to see the bathroom clearly at first sight which gives them a great first impression. Our team of qualified and certified professionals manufacture shower glass using all modern technology. Aspect provides the best Shower Enclosure Screen Installation in Dubai. Our team provides the modern look that fits your desire and your bathroom’s space. Different accessories to fit Shower Glass can also be provided as per client’s choice.",
    },
    {
      id: 2,
      img: "/img/shower--glass-partition.jpg",
      heading: "Shower Glass Partition & Enclosure",
      paragraph:
        "Shower Glass Partition and Enclosure have become an integral part of every home. Options for Shower Glass Partition at Aspect are endless, from sleek to framed shower glass and enclosure are all available. Our shower glass partition and enclosure are made up of very stiff glass which cannot break as compared to other low-quality glass. Standards at Dubai at Aspect ensure that glass is of high quality and perfectly installed keeping in view the safety parameters. A shower Glass Partition is a great way to separate wet and dry areas which keeps the room clean.",
    },
    {
      id: 3,
      img: "/img/besides-shower-glass.jpg",
      heading: "Besides Shower Glass Partition",
      paragraph:
        "Now enhance your bathroom’s look with our bathroom shower glass in Dubai. Our Glass shower doors are the best product that you can avail for your bathroom space which adds beauty to your bathroom. We offer a variety of furnishes and finishes for shower glass which can change the whole look of your bathroom.",
    },
    {
      id: 4,
      img: "/img/frameless-shower.jpg",
      heading: "Frameless Bathroom Shower Enclosures",
      paragraph:
        "Frameless Bathroom Shower enclosures are a little on the expensive side but if you want to give your bathroom a fancy yet decent and modern look, it will be worth the money spent on it. Our experts at Aspect and Glass Companies help you make the best design for Frameless Bathroom Shower Enclosures. Our frameless bathroom shower enclosures will transform the look of your bathroom. Our frameless Bathroom shower enclosures are versatile and serve various benefits. You can select any frameless bathroom shower enclosure from a broad variety of our designs. Our Frameless Bathroom Shower Enclosures serve the best styles and configurations. Metal hinges or handles can also be added as per your choice.",
    },
  ],

  sliding_glass_doors: [
    {
      id: 1,
      img: "/img/slidingGlassDoor.jpg",
      heading: "Best Sliding Glass Doors in Dubai",
      paragraph:
        "Dubai at Aspect is the best Glass Door Company with options such as sliding Glass Door replacement, sliding folding door, sliding patio door, automatic sliding door and many others. We have the best Sliding Glass Doors options for our clients either commercial use or residential use, we have all the options available at Dubai at Aspect and Glass Company. Glass is a material that can only be handled by professionals who are experts in dealing with glass and Aspect team are one of those people who know how to carry out their work professionally.",
    },
    {
      id: 2,
      img: "/img/frameless-sliding.jpg",
      heading: "Frameless Sliding Glass Door",
      paragraph:
        "Shower Glass Partition and Enclosure have become an integral part of every home. Options for Shower Glass Partition at Aspect are endless, from sleek to framed shower glass and enclosure are all available. Our shower glass partition and enclosure are made up of very stiff glass which cannot break as compared to other low-quality glass. Standards at Dubai at Aspect ensure that glass is of high quality and perfectly installed keeping in view the safety parameters.",
    },
    {
      id: 3,
      img: "/img/glass-door-dubai.jpg",
      heading: "Glass Door Dubai",
      paragraph:
        "If you are someone who is a fan of Glass Doors, you are welcomed at Dubai at Aspect who serves the best quality glass door. Our Glass Doors are designed to add elegance to your space. Various types of Glass Doors are available at our company, with various designs and colors. Our glass is highly furnished to give a complete and refined look. Our Glass Doors make the space ten times attractive once they are installed.",
    },
    {
      id: 4,
      img: "/img/revolving_glass_doors.jpg",
      heading: "Revolving Glass Doors Dubai",
      paragraph:
        "Revolving Glass Doors Dubai are ideal for commercial areas main entrance which given an expensive and decent first impression to the person entering the Revolving Glass Door. Revolving Doors are highly used in buildings with vast spaces for a masterpiece entrance with a smooth flow of traffic. Our Revolving Glass Doors are user-friendly, highly adaptable, and are available with various LED light ceiling options. Moreover, our Revolving Glass Doors are highly thermal insulated, resisting unwanted noise and worst weather conditions. These qualities are exactly what differentiates Dubai at Aspect from all other companies.",
    },
  ],

  curtain_walls: [
    {
      id: 1,
      img: "/img/curtain-walls.jpg",
      heading: "Curtain Walls",
      paragraph:
        "Aspect offers a variety of Curtain Walls in different shapes, depths, finishes, and various color options that are the worst weather resistant. We offer a huge range of Curtains Walls at Aspect to keep you safe from any weather conditions. Currently, our aluminium wall curtains are the best-selling products with commendable properties unmatchable.",
    },
    {
      id: 2,
      img: "/img/curtain-wall-dubai.jpg",
      heading: "Curtain Wall Dubai",
      paragraph:
        "Aspect offers the best Curtain Wall in Dubai. Our Curtain walls are extremely rigid and powder coated to give a fine finish and look. We offer the best color combination, from lighter to darker shades of colors available in hundreds of shades so that our clients can choose the color according to the preference and space they want to install curtain walls in. We offer the best in-room separator and partition curtain as well that are functional and aesthetic.",
    },
    {
      id: 3,
      img: "/img/curtain-wall-system.jpg",
      heading: "Aluminium Curtain Wall System",
      paragraph:
        "Aluminium serves as a plus point if you are looking for aluminium Curtain Wall system which enhances the space by serving its function. A variety of shapes, textures, colors, and finishes are available at Aspect and Glass Companies for aluminium Curtain Wall System. Glass and metal panels can also be insulated within the curtain wall as per the client’s choice. Double glazed glass with aluminium framed Curtain Walls is also available. Our aluminium Curtain Walls are manufactured from a mixture of the best materials that are highly thermal and can cope with any weather condition such as extremely windy weather, snow, or even earthquakes.",
    },
    {
      id: 4,
      img: "/img/white-wall.jpg",
      heading: "Curtains For White Walls",
      paragraph:
        "For curtains with white walls, Aspect has a huge collection through which you can select the color and design option. If you are confused about which curtain to choose for white walls, our team of experts at Aspect will give you the best advice and select the best curtains for white walls. To achieve the perfect styling of Curtains for White and grey walls, our engineers provide plans in a way that can enhance your space and make it look elegant according to your space interior and color combination without being too overwhelming.",
    },
  ],

  glass_railing: [
    {
      id: 1,
      img: "/img/glass-railing.jpg",
      heading: "Glass Railing",
      paragraph:
        "Draw out the view of your home with our unbeatable quality of glass railings which helps to enhance your living space. Glass railings aren’t something that everyone can handle as they are fragile, our glass are manufactured stronger than the usual ones that requires very low maintenance only if required. If you are someone who is looking to give your space a modern, decent and minimal look and touch glass railing is your best choice.",
    },
    {
      id: 2,
      img: "/img/glassRailing.jpg",
      heading: "Glass Handrail",
      paragraph:
        "High quality glass handrails are now available at Aspect with a wide range of patterns and designs that are not fragile but are thick, stiff and rigid manufactured to keep you safe. Our glass handrails are manufactured, powder coated and brushed into fine products with flawless finishing of wood or stainless steel available in different shapes and sizes which compliments the other interior present in your space too. Our designs are made by keeping the trend and latest fashion in our mind.",
    },
    {
      id: 3,
      img: "/img/stairs.jpg",
      heading: "Stairway Glass",
      paragraph:
        "Remodel your stairways look with our stairway glass that does not corrodes at the most rational and reasonable prices. You will be astonished at how many options do we have of Stairway glass at Aspect of premium and well refined quality that radiates modern and luxury vibes. The stairway glass in todays era has become a classic yet modern style to enhance your space. We make sure not to only manufacture a well refined stairway glass but also to install it exquisitely into your space.",
    },
    {
      id: 4,
      img: "/img/guard-railing.jpg",
      heading: "Guard Railing",
      paragraph:
        "Guard railings that are used as a boundary feature to keep people off from a limited and restricted area are now also conveniently available at Aspect manufactured and crafted from the best steel available that are easily installed and can be relocated as well without any hustle. Its accessories are also available such as its corner cap and mounting bracket.",
    },
    {
      id: 5,
      img: "/img/glass-stair-railing.jpg",
      heading: "Glass Stair Railing",
      paragraph:
        "Beside stairway glass, glass stair railing is also available at Aspect with the same high quality. Glass railing serves as an alternative of wooden handrails. Variety of divergent glass stair railing are accessible here with their different kinds of basis such as trims, connectors, base shoes, rubber set, lights, glass railing clamps and adaptors depending on what you want to opt. We make sure to secure our glass railing panels with the top most quality of railing, bottom shoe or clips (either of glass or wood) depending on the design acquired by our client.",
    },
    {
      id: 6,
      img: "/img/balustrades.jpg",
      heading: "Balustrades",
      paragraph:
        "We are experienced and specialist in balustrades, A number of sophisticated safety proof, robust and rigid balustrades are also available of high quality steel, aluminium or glass. Different types of balustrades such as frameless and post system balustrades are available. Balusters can be used in your stairway to complete the look and add a design. Designers at Aspect are available who design customized balustrades according to clients needs and provide them the exact look the want that are fitted with high strength of frame and attractive curves that can be used in staircases, balconies, porch basically anywhere the client wants.",
    },
  ],

  upvc_doors_windows: [
    {
      id: 1,
      img: "/img/upvcSlidingDoors.jpg",
      heading: "uPVC Doors",
      paragraph:
        "Aspect distinctive door designs for your home can add a touch of sophistication to your property. These doors are handcrafted using only the best quality materials and are custom-made. uPVC main doors are hinged doors that come in various patterns and styles, allowing you to quickly pick the right door for your home. They can be utilised as the primary entrance door and in the restrooms, kitchen, and bedrooms, among other places.",
    },
    {
      id: 2,
      img: "/img/upvcSlideFold.jpg",
      heading: "uPVC Slide & Fold Doors",
      paragraph:
        "uPVC Slide and Fold Doors are used mainly in large open areas to appear like a virtual wall. The door is a collection of multiple frames which has fixed or moving frames.",
    },

    {
      id: 3,
      img: "/img/upvc_lift_slide.jpg",
      heading: "uPVC Lift & Sliding Door",
      paragraph:
        "Lift and Slide uPVC range of sliding doors are designed to accommodate large openings, creating expansive glass walls with unusually large panels. These glass doors give your home the convenience and space, which a hinged door cannot provide. They can be built straight, curved, or in a corner, while panels can slide against a jamb, into a wall pocket, or along an outside wall.",
    },
    {
      id: 4,
      img: "/img/upvcSlidingWindows.jpg",
      heading: "uPVC Sliding Windows",
      paragraph:
        "An uPVC Sliding window comprise of frames that can slide back and forth. The technical term for frames here is sash. With great ventilation and aesthetics, uPVC sliding windows offer unmatchable high-quality uPVC frames coupled with Glass of choice",
    },
  ],
};

export const CareerData = [
  {
    id: 1,
    heading: "Accounts Manager",
    paragraph:
      "An Accountant or Accountant Professional is responsible for the management and reporting of financial data of an organization. Their duties include preparing financial statements, examining and analyzing a company’s accounts and ensuring compliance with financial reporting and other standard accounting procedures.",
    link: "/careers/accounts_manager",
  },
  {
    id: 2,
    heading: "Sales Manager",
    paragraph:
      "A Sales Manager, or Sales Department Manager, is responsible for overseeing daily operations in the sales department. Their duties include hiring and training sales staff, relaying information from upper management to department staff about sales quotas and generating leads to divide among Sales Representatives.",
    link: "/careers/sales_manager",
  },
  {
    id: 3,
    heading: "Assistant Accountant",
    paragraph:
      "An Accountant or Accountant Professional is responsible for the management and reporting of financial data of an organization. Their duties include preparing financial statements, examining and analyzing a company’s accounts and ensuring compliance with financial reporting and other standard accounting procedures.",
    link: "/careers/assistant_accountant",
  },
  {
    id: 4,
    heading: "Sales Representative",
    paragraph:
      "The Sales Representative is responsible for selling products and meeting customer needs while obtaining orders from existing or potential sales outlets. They ensure that the customer is satisfied and adequately taken care of while making a purchase. This way, they can establish new accounts for their employer",
    link: "/careers/sales_representative",
  },
];

export const CareerDescription = {
  accounts_manager: {
    job_description:
      "An Accountant or Accountant Professional is responsible for the management and reporting of financial data of an organization. Their duties include preparing financial statements, examining and analyzing a company’s accounts and ensuring compliance with financial reporting and other standard accounting procedures.",
    responsibilities: {
      paragraph:
        "Accountants prepare and examine financial records, ensuring records are accurate and that taxes are paid. Different types of Accountant duties and responsibilities may include the following:",
      lists: [
        "Reconciling The Company’s Bank Statements And Bookkeeping Ledgers",
        "Completing Analysis Of The Employee Expenditures",
        "Managing Income And Expenditure Accounts",
        "Generating The Company’s Financial Reports Using Income And Expenditure Data",
      ],
    },
    requirements: {
      paragraph:
        "We are seeking trustworthy candidates who work efficiently without sacrificing accuracy. Accountants can expect to work with large amounts of numerical data, handle multiple tasks with deadlines, and provide transparent and complete reports to management. You should have a firm grasp of accounting and financial practices and regulations and possess strong computer and verbal and written communication skills.",
      lists: [
        "Complying With All Company, Local, State, And Federal Accounting And Financial Regulations.",
        "Compiling, Analyzing, And Reporting Financial Data.",
        "Creating Periodic Reports, Such As Balance Sheets, Profit & Loss Statements, Etc.",
        "Presenting Data To Managers, Investors, And Other Entities.",
        "Maintaining Accurate Financial Records. Performing Audits And Resolving Discrepancies.",
      ],
    },
  },
  sales_manager: {
    job_description:
      "A Sales Manager, or Sales Department Manager, is responsible for overseeing daily operations in the sales department. Their duties include hiring and training sales staff, relaying information from upper management to department staff about sales quotas and generating leads to divide among Sales Representatives.",
    responsibilities: {
      paragraph:
        "For any business to thrive, it must have a competent Sales Manager who can plan, execute and oversee all sales and promotional activities effectively. Sales Managers typically undertake the following duties alone or in collaboration with other personnel in the sales department:",
      lists: [
        "Overseeing Local And Regional Sales, Promotions And Campaigns",
        "Planning And Directing The Hiring And Training Of New Sales Representatives",
        "Directing And Coordinating All Sales Activities Locally And Regionally",
        "Preparing Sales Budgets And Projections And Approving Expenditures",
        "Tracking And Analyzing Sales Statistics Based On Key Quantitative Metrics",
        "Handling And Resolving Customer Complaints Regarding A Product Or Service",
      ],
    },
    requirements: {
      paragraph:
        "In order to drive up sales while maintaining good relationships with customers, Sales Managers should have a passion for selling, be self-driven and have the following set of skills and qualifications:",
      lists: [
        "Ability To Set Sales Targets And Achieve Them Effectively",
        "Excellent Interpersonal, Customer Service And Communication Skills",
        "Experience Using CRM To Manage The Sales Process And Forecast Sales",
        "Strong Analytical Skills To Identify Trends And Sales Patterns",
        "Ability To Design And Implement A Successful Sales Strategy",
      ],
    },
  },
  assistant_accountant: {
    job_description:
      "We are looking for an accountant with great attention to detail, good with numbers and figures and an analytical acumen, good understanding of accounting and financial reporting principles and practices. He/She should have excellent knowledge of MS Office and familiarity with relevant computer software.",
    responsibilities: {
      paragraph:
        " Assisting the finance department and senior accounting staff members with various tasks, including preparing budgets, records, and statements",
      lists: [
        "Analyze balance sheets, income, cash flow statements, and other reports to assess accuracy.",
        "Maintain accurate month-end records by examining all financial statements.",
        "Handle monthly, quarterly and annual closings.",
        "Analyze reconciling bank statements, and monitor company finances to issue accurate and insightful reports within defined deadlines.",
      ],
    },
    requirements: {
      paragraph:
        "We are looking for an ambitious Assistant Accountant to provide support to the main business division of the finance department.",
      lists: [
        "Pertinent educational qualification in the field of Commerce, Finance, and Economics.",
        "Strong understanding of accounting aspects like balance sheets, cash flow management, business forecast, and profitability.",
        "Proven command of negotiation skills to build long-lasting partnerships with clients.",
        "Analyze reconciling bank statements, and monitor company finances to issue accurate and insightful reports within defined deadlines.",
        "Ability to prepare detailed and accurate reports within the given timeframe as per requirements.",
        "Qualified Microsoft Suite professional with a proven track record of implementing technology in financial tasks",
      ],
    },
  },
  sales_representative: {
    job_description:
      "The Sales Representative is responsible for selling products and meeting customer needs while obtaining orders from existing orpotential sales outlets. They ensure that the customer is satisfied and adequately taken care of while making a purchase. This way, they can establish new accounts for their employer.",
    responsibilities: {
      paragraph:
        "A Sales Representative uses their knowledge of company products or services to connect with consumers and generate sales for their employer",
      lists: [
        "Will be responsible for efficient coordination with clients for timely and smooth completion of job.",
        "Approach new potential clients by visiting sites, over telephone,email etc.",
        "Evaluate customers needs and build productive long-lasting relationships.",
        "Meet personal and team sales targets.",
        "Establish good relationship with current clients with follow up on their future projects.",
      ],
    },
    requirements: {
      paragraph:
        "Candidates must be able to achieve monthly sale targets and should have the ability to perform cost-benefits and needs analysis of existing/potential customers to meet their needs. Establish, develop and maintain positive business and customer problems and complaints to maximize satisfaction. Achieve agreed upon sales targets and outcomes within schedule Coordinate sales effort with team members and other departments keep abreast of best practices and promotional trends.",
      lists: [
        "Excellent communication, interpersonal and negotiation skills.",
        "Must be target oriented to achieve monthly Sale targets.",
        "Proven Sales experience and Self-motivated.",
        "Report and provide feedback to management weekly wise.",
        "Maintain and expand client database within your assigned territory Requirements and skills.",
        "Sending quotes to prospective clients, drafting contracts, Negotiations of commercial terms.",
        " Maintaining relationships with existing clients, Maintaining database of leads and clients in CRM.",
      ],
    },
  },
};
