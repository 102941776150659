// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./headerSwiper.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const HeaderSwiper = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        speed={2000}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        loop={true}
      >
        <SwiperSlide>
          <div className="slider slider-1">
            <h1 className="main-heading">
              Welcome to aspect aluminium & glass
            </h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="slider slider-2">
            <h1 className="main-heading">
              Welcome to aspect aluminium & glass
            </h1>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="slider slider-3">
            <h1 className="main-heading">
              Welcome to aspect aluminium & glass
            </h1>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HeaderSwiper;
