import Cards from "../../ui/cards/Cards";
import "./service.css";
import { cardsData } from "../../cardData";

const Service = () => {
  return (
    <div className="service-container">
      <div id="service" className="service">
        <h2 className="heading">our services</h2>
        <div className="cards">
          {cardsData["serviceCard"].map((card) => (
            <Cards key={card.id} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
