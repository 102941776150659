import "./homeCard.css";
import Cards from "../../ui/cards/Cards";
import { cardsData } from "../../cardData";

const HomeCard = () => {
  return (
    <div className="homecard-container">
      <div id="homeCard" className="homeCard">
        <div className="cards">
          {cardsData["homeCard"].map((card) => (
            <Cards key={card.id} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
