import "react-toastify/dist/ReactToastify.css";
import "./contact.css";

import {
  LocationOn,
  Email,
  Phone,
  PhoneIphone,
  LinkedIn,
  Twitter,
  Instagram,
  Facebook,
} from "@mui/icons-material";
import Form from "./Form";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";

const Contact = () => {
  const { darkMode } = useContext(DarkModeContext);
  return (
    <>
      <div className="contact">
        <Form />
        <div className="contact-info">
          <h2 className="form-header">contact information</h2>
          <div className="links">
            <a href="https://www.google.com/maps/place/25%C2%B007'25.1%22N+55%C2%B013'35.3%22E/@25.1236389,55.2264722,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x2dcc1077c2f4c320!8m2!3d25.1236381!4d55.2264701?hl=en">
              <LocationOn className="icon" />
              <span>
                Al Asayel Street, Al Quoz Industrial Area 3, Dubai, UAE
              </span>
            </a>
            <a href="tel:+97145586191">
              <Phone className="icon" />
              +971 4 558 6191
            </a>
            <a href="tel:+971526139715">
              <PhoneIphone className="icon" />
              +971 52 613 9715
            </a>
            <a href="mailto:sales@aspectuae.com">
              <Email className="icon" />
              sales@aspectuae.com
            </a>
          </div>
          <div className="socialMedia">
            <a href="https://www.facebook.com/profile.php?id=100086641806388">
              <Facebook className="icon" />
            </a>
            <a href="https://twitter.com/AspectAluminum">
              <Twitter className="icon" />
            </a>
            <a href="https://www.instagram.com/aspectaluminiumuae/">
              <Instagram className="icon" />
            </a>
            <a href="https://www.instagram.com/aspectaluminium/">
              <LinkedIn className="icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="map">
        <iframe
          title="aspect-aluminium"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14449.429597229378!2d55.2265178!3d25.1236051!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x292f72abc4a483d4!2sAspect%20Aluminium%20%26%20Glass!5e0!3m2!1sen!2sae!4v1666613959994!5m2!1sen!2sae"
          width="100%"
          height="450"
          frameborder="0"
          aria-hidden="false"
          tabIndex="0"
          style={{
            filter: darkMode ? "invert(90%) hue-rotate(180deg)" : "none",
          }}
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
