import { useRef, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast, Slide } from "react-toastify";

import emailjs from "@emailjs/browser";

const Form = () => {
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const form = useRef();
  const [phone, setPhone] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const validMessage = new RegExp(/^[\w\-\s\n]{10,}$/).test(
    values.message.trim()
  );
  const toastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    bodyClassName: "toast-font-size",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (validMessage) {
      setSubmitted(false);
      await emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            toast(
              "Thank you for contacting us. We will get back to you shortly.",
              toastOptions
            );
          },
          (error) => {
            toast(
              "Sorry, There's an error occured. Try again later ",
              toastOptions
            );
          }
        );

      setValues({
        name: "",
        email: "",
        message: "",
      });
      setPhone();
    }
    setButtonDisabled(false);
  };
  return (
    <>
      {" "}
      <div className="form-container">
        <ToastContainer />
        <h2 className="form-header">send us message</h2>
        <form className="form" ref={form} onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              name="name"
              required
              title="please enter your name"
              placeholder="enter your name here"
              onChange={onChange}
              value={values.name}
              submitted={submitted.toString()}
              onFocus={() => setSubmitted(false)}
              // onBlur={() => setSubmitted(true)}
              // pattern="^[A-Za-z0-9]{3,}$"
            />
            <span className="errorMessage">Enter Valid Name</span>
          </div>
          <div className="input-group small email">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              required
              title="please enter your email"
              placeholder="enter your email here"
              onChange={onChange}
              submitted={submitted.toString()}
              onFocus={() => setSubmitted(false)}
              // onBlur={() => setSubmitted(true)}
              value={values.email}
            />
            <span className="errorMessage">Enter Valid Email</span>
          </div>
          <div className="input-group small">
            <label htmlFor="phone">Phone Number</label>
            <PhoneInput
              id="phone"
              type="tel"
              name="phone"
              required
              title="please enter your contact number"
              placeholder="enter your number here"
              international
              defaultCountry="AE"
              value={phone}
              pattern="^\+?[0-9\s]{4,}$"
              onChange={setPhone}
              submitted={submitted.toString()}
              onFocus={() => setSubmitted(false)}
              // onBlur={() => setSubmitted(true)}
            />
            <span className="errorMessage">Enter Valid Phone Number</span>
          </div>
          <div className="input-group">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              rows="6"
              placeholder="how can we help you? or you us?"
              required
              title="please write some message"
              onChange={onChange}
              value={values.message}
              submitted={(submitted && !validMessage).toString()}
              onFocus={() => setSubmitted(false)}
              // onBlur={() => setSubmitted(true)}
            ></textarea>

            <span className="errorMessage">
              Message should have 10 characters minimum and shouldn't include
              any special character!
            </span>
          </div>
          <button
            onClick={() => setSubmitted(true)}
            disabled={buttonDisabled}
            type="submit"
            className="btn input-btn"
          >
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default Form;
