import "./header.css";
import HeaderSwiper from "./swiper/HeaderSwiper";

const Header = () => {
  return (
    <div className="header">
      <HeaderSwiper />
    </div>
  );
};

export default Header;
