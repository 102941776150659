import "./products.css";

const Products = (product) => {
  return (
    <div className="products-container">
      <div className={product.id % 2 ? "products" : "products direction"}>
        <div className="products_img">
          <div
            className="img"
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
          >
            <img src={product.img} alt={product.heading} />
          </div>
        </div>

        <div className="product_details">
          <h2 className="heading-secondary">{product.heading}</h2>
          <p>{product.paragraph}</p>
        </div>
      </div>
    </div>
  );
};

export default Products;
