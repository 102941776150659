import "./whoWeare.css";

const WhoWeAre = () => {
  return (
    <div className="whoweare-container">
      <div className="whoweare">

      <h2 className="heading-secondary">who we are</h2>
      <p>
        Aspect Aluminium & Glass Industry LLC is part of Aspect Group based in
        Dubai. The company is one of the leading companies in the Dubai region.
        In addition to our past projects, we always offer a complete product
        development, design and manufacture service – using our knowledge &
        experience within the field to create design, functional and innovative
        solutions. We, provide you with any technical assistance & quotation
        related to your projects and requirements and to suit your budget.
        Aspect – UAE, is a design, manufacture & installation company based in
        Dubai, United Arab Emirates and specializing in creative, design
        products & solutions.
      </p>
      <div className="whoweare_img">
        <img
          src="https://i.natgeofe.com/n/f3d0b742-5bef-4665-87e8-61ef82a0101c/dubai-travel_square.jpg"
          alt="leading comapany in dubai"
          />
        <img
          src="https://cdn.britannica.com/85/171185-050-135771D4/Burj-Khalifa-Dubai-United-Arab-Emirates.jpg"
          alt="leading comapany in dubai"
          />
      </div>
          </div>
    </div>
  );
};

export default WhoWeAre;
