import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";

const ContactPage = () => {
  return (
    <div>
      <Navbar />
      <SimpleHeader title={"contact us"} />
      <div className="contact-container">
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
