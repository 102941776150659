import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";
import Products from "../ui/products/Products";
import { ProductData } from "../cardData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const ProductDetails = () => {
  const navigate = useNavigate();
  const product = useParams().prod;
  if (!ProductData[product]) console.log("not found");
  let title = product.split("_").join(" ");
  title = title.replace("upvc", "uPVC");

  useEffect(() => {
    if (!ProductData[product]) {
      return navigate("/");
    }
  }, [product, navigate]);

  return (
    <div>
      <Navbar />
      <SimpleHeader title={title} />
      {ProductData[product]?.map((p) => (
        <Products key={p.id} {...p} />
      ))}
      <Footer />
    </div>
  );
};

export default ProductDetails;
