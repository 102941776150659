import "./simpleHeader.css";

const SimpleHeader = ({ title }) => {
  return (
    <div className="simpleHeader">
      <h2 className="main-heading">{title}</h2>
    </div>
  );
};

export default SimpleHeader;
