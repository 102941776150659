import { Link } from "react-router-dom";
import "./stairsAndGlass.css";

const StairsAndGlass = () => {
  return (
    <div className="section" id="section">
      <div className="sectionContainer">
        <div className="flex-1">
          <div className="img">
            <img src="/img/stairs.jpg" alt="Aluminium stair case" />
          </div>
          <div className="flex-content">
            <h2 className="heading-secondary">Aluminium Stair Case</h2>
            <p>
              Staircases nowadays have become an important part of the
              architecture world. Stairs are the focal point of a space due to
              which it is very necessary to install yourself a great design of
              stairs that are durable and minimal. Aspect offers all top quality
              highly finished and welded stairs meet all the standards of
              clients. Glass, wood, aluminium, metal, and stainless steel
              everything you need is available here
            </p>
            <Link to={"/aluminium/aluminium_staircase"}>
              <button className="btn">view more</button>
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex-content">
            <h2 className="heading-secondary">Glass Partition</h2>
            <p>
              Glass partition which is the feature of interior design creates an
              aesthetically attractive pleasing environment which gives a
              tasteful impression be it at an office or even at home. Glass
              partition allow much more light to pass through – making for a
              brighter, airier environment. Natural lightening system has been
              provided for better vision. We at Aspect understand our audience
              and entertain them with the best moderate thoughtful style.
            </p>
            <Link to={"/glass/glass_partition"}>
              <button className="btn">view more</button>
            </Link>
          </div>
          <div className="img">
            <img src="/img/glass-partition.jpg" alt="Glass Partition" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StairsAndGlass;
