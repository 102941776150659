import "./careerlist.css";
import { CareerData } from "../../../cardData";
import { Link } from "react-router-dom";

const CareerList = () => {
  return (
    <div className="career-container">
      <div className="career">
        <h2 className="heading">apply now</h2>
        <p>
          If you are looking to work in a fun and driven environment with
          colleagues from all walks of life, including designers, developers,
          testers, DevOps engineers, project managers, and researchers,
          passionate about learning and delivering, this is the place for you.
          If your career ethics align with our company, drop your resume at
          <Link to="mailto:admin@aspectuae.com"> admin@aspectuae.com</Link>
        </p>
        <h3 className="heading">Open Positions</h3>

        {CareerData.map((job) => (
          <div className="job" key={job.id}>
            <h3 className="heading-secondary">{job.heading}</h3>
            <p>{job.paragraph}</p>
            <Link to={job.link}>
              <button className="btn">Read More</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerList;
