import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";
import WhoWeAre from "../components/whoWeare/WhoWeAre";

const About = () => {
  return (
    <div>
      <Navbar />
      <SimpleHeader title={"about us"} />
      <WhoWeAre />
      <Footer />
    </div>
  );
};

export default About;
