import React from "react";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import ServiceList from "../components/serviceList/ServiceList";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";

const Products = ({ product }) => {
  return (
    <div>
      <Navbar />
      <SimpleHeader title={product} />
      <ServiceList list={product} />
      <Footer />
    </div>
  );
};

export default Products;
