import "./navbar.css";
import { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { DarkMode, LightMode } from "@mui/icons-material";
import { DarkModeContext } from "../../context/darkModeContext";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  return (
    <>
      <nav className="navbar">
        <Link to="/" className="logo">
          <img
            src="/img/logo.png"
            alt="aspect-global-logo"
            className="navbar-logo-image"
          />
        </Link>
        <div className="menu">
          {open ? (
            <CloseIcon className="menu-icon" onClick={() => setOpen(false)} />
          ) : (
            <MenuIcon className="menu-icon" onClick={() => setOpen(true)} />
          )}
        </div>

        <ul
          className={open ? "nav__links active" : "nav__links"}
          onClick={() => setOpen(false)}
        >
          <li>
            <NavLink
              to="/aluminium/"
              className={({ isActive }) =>
                isActive ? "nav__link navActive" : "nav__link"
              }
            >
              Aluminium
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav__link navActive" : "nav__link"
              }
              to="/glass/"
            >
              Glass
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav__link navActive" : "nav__link"
              }
              to="/upvc/"
            >
              UPVC
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav__link navActive" : "nav__link"
              }
              to="/about_us"
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav__link navActive" : "nav__link"
              }
              to="/our_projects"
            >
              Our Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav__link navActive" : "nav__link"
              }
              to="/contact_us"
            >
              Contact
            </NavLink>
          </li>
          <li className="mode-icon" onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? (
              <Tooltip title="light mode">
                <button>
                  <LightMode className="icon" />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="dark mode">
                <button>
                  <DarkMode className="icon" />
                </button>
              </Tooltip>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
