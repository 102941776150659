import "./gallery.css";

import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "img/assets/g1.jpg",
    thumbnail: "img/assets/g1.jpg",
  },
  {
    original: "img/assets/g2.jpg",
    thumbnail: "img/assets/g2.jpg",
  },
  {
    original: "img/assets/g3.jpg",
    thumbnail: "img/assets/g3.jpg",
  },
  {
    original: "img/assets/g4.jpg",
    thumbnail: "img/assets/g4.jpg",
  },
  {
    original: "img/assets/g5.jpg",
    thumbnail: "img/assets/g5.jpg",
  },
  {
    original: "img/assets/g6.jpg",
    thumbnail: "img/assets/g6.jpg",
  },
  {
    original: "img/assets/g7.jpg",
    thumbnail: "img/assets/g7.jpg",
  },
  {
    original: "img/assets/g8.png",
    thumbnail: "img/assets/g8.png",
  },
  {
    original: "img/assets/g9.png",
    thumbnail: "img/assets/g9.png",
  },
  {
    original: "img/assets/g10.png",
    thumbnail: "img/assets/g10.png",
  },
  {
    original: "img/assets/g11.png",
    thumbnail: "img/assets/g11.png",
  },
  {
    original: "img/assets/g12.png",
    thumbnail: "img/assets/g12.png",
  },
  {
    original: "img/assets/g13.png",
    thumbnail: "img/assets/g13.png",
  },
  {
    original: "img/assets/g14.png",
    thumbnail: "img/assets/g14.png",
  },
  {
    original: "img/assets/g15.png",
    thumbnail: "img/assets/g15.png",
  },
  {
    original: "img/assets/g16.png",
    thumbnail: "img/assets/g16.png",
  },
  {
    original: "img/assets/g17.png",
    thumbnail: "img/assets/g17.png",
  },
  {
    original: "img/assets/g18.png",
    thumbnail: "img/assets/g18.png",
  },
  {
    original: "img/assets/g19.png",
    thumbnail: "img/assets/g19.png",
  },
  {
    original: "img/assets/g20.png",
    thumbnail: "img/assets/g20.png",
  },
  {
    original: "img/assets/g21.png",
    thumbnail: "img/assets/g21.png",
  },
];

const Gallery = () => {
  return (
    <div className="gallery">
      <ImageGallery items={images} lazyLoad={true} />;
    </div>
  );
};

export default Gallery;
