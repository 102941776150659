import "./app.css";
import "./darkmodeStyles/dark.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import Home from "./pages/Home";
import Products from "./pages/Products";
import ContactPage from "./pages/ContactPage";
import About from "./pages/About";
import Projects from "./pages/Projects";
import ProductDetails from "./pages/ProductDetails";
import Careers from "./pages/Careers";
import { DarkModeContext } from "./context/darkModeContext";
import ScrollToTop from "./ui/ScrollToTop";
import { ParallaxProvider } from "react-scroll-parallax";
import Career from "./pages/Career";
import Kitchen from "./pages/Kitchen";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className={darkMode ? "root-container dark" : "root-container"}>
      <BrowserRouter>
        <div className="sticky-whatsapp">
          <a href="https://wa.me/+971526139715">
            <picture>
              <source
                media="(max-width: 1000px)"
                srcSet="/img/whatsapp-logo.png"
              />
              <img src="/img/whatsapp.png" alt="contact us on whatsapp" />
            </picture>
          </a>
        </div>
        <ParallaxProvider>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about_us" element={<About />} />
              <Route path="/contact_us" element={<ContactPage />} />

              <Route path="/aluminium">
                <Route index element={<Products product={"aluminium"} />} />
                <Route path=":prod" element={<ProductDetails />} />
              </Route>
              <Route path="/glass">
                <Route index element={<Products product={"glass"} />} />
                <Route path=":prod" element={<ProductDetails />} />
              </Route>
              <Route path="/upvc">
                <Route index element={<Products product={"upvc"} />} />
                <Route path=":prod" element={<ProductDetails />} />
              </Route>

              <Route path="/our_projects" element={<Projects />} />
              <Route path="/careers">
                <Route index element={<Careers />} />
                <Route path=":job" element={<Career />} />
                {/* <Route
                  path="sales"
                  element={
                    <Careers render={"job_details"} title={"sales-manager"} />
                  }
                />
                <Route
                  path="assistant_accountant"
                  element={
                    <Careers
                      render={"job_details"}
                      title={"assistant-accountant"}
                    />
                  }
                />
                <Route
                  path="sales_representative"
                  element={
                    <Careers
                      render={"job_details"}
                      title={"sales-representative"}
                    />
                  }
                /> */}
              </Route>
              <Route path="/kitchen_and_cabinets" element={<Kitchen />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </ScrollToTop>
        </ParallaxProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
