import CareerList from "../components/careersData/careerList/CareerList";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";

const Careers = () => {
  return (
    <div>
      <Navbar />
      <SimpleHeader title="Careers" />
      <CareerList />
      <Footer />
    </div>
  );
};

export default Careers;
