import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CareerDetails from "../components/careersData/career_details/CareerDetails";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SimpleHeader from "../components/simpleHeader/SimpleHeader";
import { CareerDescription } from "../cardData";

const Career = () => {
  const navigate = useNavigate();
  const job = useParams().job;
  useEffect(() => {
    if (!CareerDescription[job]) {
      return navigate("/");
    }
  }, [job, navigate]);

  let title = job?.split("_").join(" ");

  return (
    <div>
      <Navbar />
      <SimpleHeader title={title} />
      <CareerDetails CareerData={{ title, ...CareerDescription[job] }} />
      <Footer />
    </div>
  );
};

export default Career;
